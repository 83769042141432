<template>
  <section>
    <h4 class="title">
      {{ $tc("process_area", 2) }}
    </h4>
    <SearchableTable
      v-bind:items="items"
      v-bind:commands="commands"
      v-bind:fields="fields"
      v-bind:searchEnabled="true"
      v-bind:showSelected="true"
      v-on:select="onSelect"
      v-on:unselect="onUnselect"
      v-on:command="onCommand"
      searchClass="entity-search"
    />
  </section>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
export default {
  name: "ProcessAreaTable",
  components: {
    SearchableTable
  },
  data() {
    return {
      fields: [
        {
          name: "name",
          title: "name"
        },
        {
          name: "description",
          title: "description"
        }
      ],
      commands: [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => this.$can("manage", "AreaDeProcessoCadastro")
        }
      ]
    };
  },
  computed: {
    items() {
      return this.$store.getters["processArea/processAreas"] || [];
    }
  },
  methods: {
    onCommand(command) {
      if (command.name == "create") {
        this.$emit("create");
      }
    },
    onSelect(item) {
      this.$emit("select", item);
    },
    onUnselect(item) {
      this.$emit("unselect");
    }
  }
};
</script>

<style lang="scss" scoped>
.searchable-table {
  height: 92%;
}
</style>
